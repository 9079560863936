export default [
  {
    path: 'spares',
    name: 'spares',
    component: () => import('@/views/Base/Base.vue'),
    meta: {
      title: 'lang.cpq.label.sparePartsApply',
      icon: 'ticket',
    },
    children: [
      {
        path: 'apply/list',
        name: 'sparesApplyList',
        component: () => import('@/views/Spares/Apply/List.vue'),
        meta: {
          title: 'lang.cpq.label.sparePartsApply',
          breadcrumb: [
            {
              title: 'lang.cpq.label.sparePartsApply',
              path: '/spares/apply/list',
            },
            {
              title: 'lang.cpq.label.sparePartsApply',
            },
          ],
        },
      },
    ],
  },
];
